.card {
  background: white;
  border: 1px solid #04459924;
  border-radius: 10px;
  margin: 1rem;
  text-align: center;
  height: fit-content;
}

.card:hover {
  border: 1px solid #044599;
}
