.close {
  color: #fff;
  background-color: rgb(4 69 153);
  position: absolute;
  top: 23px;
  left: 27px;
  font-size: 3rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 0px;
}
.close:hover,
.close:focus {
  color: #c5c0c0;
  background-color: #013475;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
