.chains_filter {
  flex: 1;
}
.chains_filter li {
  list-style-type: none;
}
.filter_title {
  text-align: center;
  color: #044599;
}
.purge_btn {
  background-color: #b60000;
  border-radius: 5px;
  display: block;
  margin: auto;
  width: 80%;
  padding: 5px;
  color: white;
  font-weight: 700;
  border: none;
}
.purge_btn_disabled {
  opacity: 30%;
  background-color: #727272;
  border-radius: 5px;
  display: block;
  margin: auto;
  width: 80%;
  padding: 5px;
  color: white;
  font-weight: 700;
  border: none;
}
ul li label {
  color: #044599;
}
