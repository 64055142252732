.hotel_item h3 {
  color: #044599;
}
.hotel_item img {
  width: 80%;
  height: 12rem;
  object-fit: contain;
  border-radius: 5px;
  display: inline-block;
  margin: 8px auto;
}

.hotel_item_button {
  background-color: #ffffff;
  color: #044599;
  border-radius: 5px;
  padding: 0.5rem;
  font-weight: 600;
  border: 0px;
  font-family: sans-serif;
  width: 40%;
  display: inline-block;
  margin: 1rem auto;
}
.hotel_item_button:hover {
  color: #04459924;
  cursor: pointer;
}
