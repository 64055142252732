.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.hotel_details {
  position: fixed;
  top: 18%;
  right: 25%;
  z-index: 10;
  height: 50vh;
  width: 50vw;
  text-align: center;
  display: flex;
  justify-content: center;
}
.details_properties {
  width: 50%;
}
.details_properties_text {
  width: 50%;
  margin: auto;
  margin-top: 50px;
  overflow: auto;
  max-height: 70%;
}
.details_properties h2 {
  color: #044599;
  font-size: 2rem;
}
.details_properties h3 {
  margin-bottom: 0;
  text-align: left;
  color: #044599;
  font-size: 1.2rem;
}
.details_properties p {
  margin-top: 0;
  color: #044599;
  font-size: 1.2rem;
  text-align: left;
}
.details_img {
  width: 50%;
  /*margin: 5% 10%;*/
  align-self: center;
}
.details_img img {
  max-width: 100%;
}
.edit_button {
  background-color: white;
  border: none;
  color: #044599;
}
.edit_button:hover {
  color: #3760956f;
  cursor: pointer;
}
