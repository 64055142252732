.browse_hotels {
  display: flex;
}
.create_hotel_button {
  background-color: #f80021;
  color: white;
  border-radius: 5px;
  padding: 0.5rem 0rem;
  font-weight: 600;
  border: 0px;
  font-family: sans-serif;

  display: block;
  margin: 1rem auto;
}
.create_hotel_button:hover {
  background-color: rgb(248, 40, 189);
  cursor: pointer;
}

h1 {
  text-align: center;
  font-size: 4rem;
  color: #044599;
}
